<template>
  <div class="w-full max-h-screen">
    <div v-if="showModal">
      <Modal
        v-for="message in modalMessages"
        :key="message.id"
        :id="message.id"
        @close="closeModal"
      >
        <template v-slot:header>{{ message.message }} </template>

        <template v-slot:body> {{ message.description }} </template>
      </Modal>
    </div>
    <div v-if="user.type === 'Internal'">
      <Dashboard />
    </div>
    <div v-else class="w-full p-5">
      <Welcome />
    </div>
    <InformationModal
      :show="showFreeTrialInfoModal"
      @update:show="showFreeTrialInfoModal = $event"
      @confirm="showFreeTrialInfoModal = false"
      :title="'Free trial subscription'"
      :content="getFreeTrialInfoContent()"
      :width="'700px'"
      :confirmationText="'Continue'"
      :isHtml="true"
    />
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import Modal from "@/components/general/Modal.vue";
import { getListMessages } from "@/services/user/user.js";
import { useToast } from "vue-toastification";
import { useCookies } from "vue3-cookies";
import Dashboard from "@/views/Dashboard.vue";
import Welcome from "@/views/Welcome.vue";
import { ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import InformationModal from "@/components/profile/modals/InformationModal.vue";

const toast = useToast();
const { cookies } = useCookies();
const route = useRoute();
const store = useStore();

const user = computed(() => store.getters["user/getUserData"]);

const messages = ref([]);
const showModal = ref(true);
const modalMessages = ref([]);
const cookiesMessages = ref([]);
const showFreeTrialInfoModal = ref(false);

onMounted(() => {
  const { isFreeTrial } = route.query;
  const freeTrialModalShowed = verifyFreeTrialModalShowed();
  
  if (isFreeTrial === "true" && !freeTrialModalShowed) {
    showFreeTrialInfoModal.value = true;
    cookies.set("freeTrialModalShowed", true);
  }
  if (user.value.type !== "Internal") {
    if (route.query.messages) {
      getMessages();
    }
  }
});

function getMessages() {
  getListMessages().then((response) => {
    messages.value = response.data;
    filterMessages();
  });
}

function filterMessages() {
  verifyCookieExists();
  for (const message of messages.value) {
    if (message["concealable"] && !verifyMessageExists(message.id)) {
      modalMessages.value.push(message);
    }
  }
  if (modalMessages.value.length === 0) {
    showModal.value = false;
    showPopUpMessages();
  }
}

function showPopUpMessages() {
  for (const message of messages.value) {
    if (!message["concealable"]) {
      toast.info(message["message"], { timeout: false });
    }
  }
}

function closeModal(data) {
  if (data[1]) {
    cookiesMessages.value.push(data[0]);
  }
  modalMessages.value.pop();
  if (modalMessages.value.length === 0) {
    cookies.set("messages", JSON.stringify(cookiesMessages));
    showPopUpMessages();
  }
}

function verifyCookieExists() {
  cookiesMessages.value = JSON.parse(cookies.get("messages")) ?? [];
}

function verifyMessageExists(messageId) {
  return cookiesMessages.value.includes(messageId);
}

function verifyFreeTrialModalShowed() {
  return cookies.get("freeTrialModalShowed") === "true";
}

function getFreeTrialInfoContent() {
  return `Welcome and congratulations on starting your free 3-day trial. 
  You have access to the entire training program during this period; 
  if you have questions or need help, please reach out to 
  <a href="mailto:support@mastermindsports.com" class="underline">support@mastermindsports.com</a>.`;
}
</script>
