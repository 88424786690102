<template>
  <div class="m-4">
    <div class="px-20">
      <div v-if="isLoading" class="text-center mt-48">
        <em
          class="fa fa-spinner fa-spin text-white"
          style="font-size: 24px"
        ></em>
      </div>
      <LocationsTable
        v-else
        :labels="['Metric', 'Count']"
        :bottomArrows="false"
      >
        <template v-slot:table-content>
          <tr class="bg-white p-5">
            <td class="text-center">Total Active Users (Both Platforms)</td>
            <td class="text-center">{{ overviewData.totalActiveUsers }}</td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">Total Active Users - VR</td>
            <td class="text-center">{{ overviewData.totalActiveUsersVr }}</td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">Total Active Users - Tablet</td>
            <td class="text-center">
              {{ overviewData.totalActiveUsersTablet }}
            </td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">Completed Training Sessions - Prior Day</td>
            <td class="text-center">
              {{ overviewData.completedTrainingSessionsYesterday }}
            </td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">
              Completed Training Sessions - Last 7 Days
            </td>
            <td class="text-center">
              {{ overviewData.completedTrainingSessionsLastSevenDays }}
            </td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">
              Completed Training Sessions - Current Month
            </td>
            <td class="text-center">
              {{ overviewData.completedTrainingSessionsThisMonth }}
            </td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">
              Completed Training Sessions - Prior Month
            </td>
            <td class="text-center">
              {{ overviewData.completedTrainingSessionsLastMonth }}
            </td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">New Registrations - Prior Day</td>
            <td class="text-center">
              {{ overviewData.usersCreatedYesterday }}
            </td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">New Registrations - Last 7 Days</td>
            <td class="text-center">
              {{ overviewData.usersCreatedLastSevenDays }}
            </td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">New Registrations - Current Month</td>
            <td class="text-center">
              {{ overviewData.usersCreatedThisMonth }}
            </td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">New Registrations - Prior Month</td>
            <td class="text-center">
              {{ overviewData.usersCreatedLastMonth }}
            </td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">Cancelled Users - Prior Day</td>
            <td class="text-center">
              {{ overviewData.usersCanceledYesterday }}
            </td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">Cancelled Users - Last 7 Days</td>
            <td class="text-center">
              {{ overviewData.usersCanceledLastSevenDays }}
            </td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">Cancelled Users - Current Month</td>
            <td class="text-center">
              {{ overviewData.usersCanceledThisMonth }}
            </td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">Cancelled Users - Prior Month</td>
            <td class="text-center">
              {{ overviewData.usersCanceledLastMonth }}
            </td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">Account Created but no Reg - Prior Day</td>
            <td class="text-center">{{ overviewData.unpaidUsersYesterday }}</td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">
              Account Created but no Reg - Last 7 Days
            </td>
            <td class="text-center">
              {{ overviewData.unpaidUsersLastSevenDays }}
            </td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">
              Account Created but no Reg - Current Month
            </td>
            <td class="text-center">{{ overviewData.unpaidUsersThisMonth }}</td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">
              Account Created but no Reg - Prior Month
            </td>
            <td class="text-center">{{ overviewData.unpaidUsersLastMonth }}</td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">Active Facilities/Organizations</td>
            <td class="text-center">{{ overviewData.totalLocations }}</td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">
              New Facilities/Organizations - Prior Day
            </td>
            <td class="text-center">
              {{ overviewData.locationsCreatedYesterday }}
            </td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">
              New Facilities/Organizations - Last 7 Days
            </td>
            <td class="text-center">
              {{ overviewData.locationsCreatedLastSevenDays }}
            </td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">
              New Facilities/Organizations - Current Month
            </td>
            <td class="text-center">
              {{ overviewData.locationsCreatedThisMonth }}
            </td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">
              New Facilities/Organizations - Prior Month
            </td>
            <td class="text-center">
              {{ overviewData.locationsCreatedLastMonth }}
            </td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">
              Cancelled Facilities/Organizations - Prior Day
            </td>
            <td class="text-center">
              {{ overviewData.locationsCanceledYesterday }}
            </td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">
              Cancelled Facilities/Organizations - Last 7 Days
            </td>
            <td class="text-center">
              {{ overviewData.locationsCanceledLastSevenDays }}
            </td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">
              Cancelled Facilities/Organizations - Current Month
            </td>
            <td class="text-center">
              {{ overviewData.locationsCanceledThisMonth }}
            </td>
          </tr>
          <tr class="bg-white p-5">
            <td class="text-center">
              Cancelled Facilities/Organizations - Prior Month
            </td>
            <td class="text-center">
              {{ overviewData.locationsCanceledLastMonth }}
            </td>
          </tr>
        </template>
      </LocationsTable>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { getUsersOverview } from "@/services/user/user.js";
import LocationsTable from "@/components/location/LocationsTable.vue";

const overviewData = ref({
  totalActiveUsers: 0,
  totalActiveUsersVr: 0,
  totalActiveUsersTablet: 0,
  usersCreatedYesterday: 0,
  usersCreatedLastSevenDays: 0,
  usersCreatedThisMonth: 0,
  usersCreatedLastMonth: 0,
  usersCanceledYesterday: 0,
  usersCanceledLastSevenDays: 0,
  usersCanceledThisMonth: 0,
  usersCanceledLastMonth: 0,
  usersCanceledLastThirtyDays: 0,
  totalLocations: 0,
  locationsCreatedYesterday: 0,
  locationsCreatedLastSevenDays: 0,
  locationsCreatedThisMonth: 0,
  locationsCreatedLastMonth: 0,
  locationsCanceledYesterday: 0,
  locationsCanceledLastSevenDays: 0,
  locationsCanceledThisMonth: 0,
  locationsCanceledLastMonth: 0,
  totalLocationAdmins: 0,
  unpaidUsersYesterday: 0,
  unpaidUsersLastSevenDays: 0,
  unpaidUsersThisMonth: 0,
  unpaidUsersLastMonth: 0,
  completedTrainingSessionsYesterday: 0,
  completedTrainingSessionsLastSevenDays: 0,
  completedTrainingSessionsThisMonth: 0,
  completedTrainingSessionsLastMonth: 0,
});
const isLoading = ref(true);

onMounted(async () => {
  try {
    const response = await getUsersOverview();

    overviewData.value = {
      totalActiveUsers: response.total_active_users,
      totalActiveUsersVr: response.total_active_users_vr,
      totalActiveUsersTablet: response.total_active_users_tablet,
      usersCreatedYesterday: response.users_created_yesterday,
      usersCreatedLastSevenDays: response.users_created_last_seven_days,
      usersCreatedThisMonth: response.users_created_this_month,
      usersCreatedLastMonth: response.users_created_last_month,
      usersCanceledYesterday: response.users_canceled_yesterday,
      usersCanceledLastSevenDays: response.users_canceled_last_seven_days,
      usersCanceledThisMonth: response.users_canceled_this_month,
      usersCanceledLastMonth: response.users_canceled_last_month,
      usersCanceledLastThirtyDays: response.users_canceled_last_thirty_days,
      totalLocations: response.total_locations,
      locationsCreatedYesterday: response.locations_created_yesterday,
      locationsCreatedLastSevenDays: response.locations_created_last_seven_days,
      locationsCreatedThisMonth: response.locations_created_this_month,
      locationsCreatedLastMonth: response.locations_created_last_month,
      locationsCanceledYesterday: response.locations_canceled_yesterday,
      locationsCanceledLastSevenDays:
        response.locations_canceled_last_seven_days,
      locationsCanceledThisMonth: response.locations_canceled_this_month,
      locationsCanceledLastMonth: response.locations_canceled_last_month,
      totalLocationAdmins: response.total_location_admins,
      unpaidUsersYesterday: response.unpaid_users_yesterday,
      unpaidUsersLastSevenDays: response.unpaid_users_last_seven_days,
      unpaidUsersThisMonth: response.unpaid_users_this_month,
      unpaidUsersLastMonth: response.unpaid_users_last_month,
      completedTrainingSessionsYesterday:
        response.completed_training_sessions_yesterday,
      completedTrainingSessionsLastSevenDays:
        response.completed_training_sessions_last_seven_days,
      completedTrainingSessionsThisMonth:
        response.completed_training_sessions_this_month,
      completedTrainingSessionsLastMonth:
        response.completed_training_sessions_last_month,
    };
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    isLoading.value = false;
  }
});
</script>

<style scoped>
img {
  border-radius: 50%;
  background-color: #edfe86;
  width: 52px;
  height: 52px;
}

#card {
  width: 30%;
}

table {
  border: none;
}

th {
  color: #1d2e48;
  background: #ecf0f5;
}

tr {
  border-top: 1px solid #ecf0f5;
  border-bottom: 2px solid #ecf0f5;
}

th,
td {
  border: none;
}

tr td {
  padding: 20px;
}

tr:hover td {
  background: #4e5066;
  color: #ffffff;
}

tr:first-child {
  border-top: none;
}

tr:last-child {
  border-bottom: none;
}
</style>
