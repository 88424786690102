<template>
  <div>
    <div v-if="user.role === 'Location_admin'">
      <DashboardHeader />
    </div>
    <div class="h-screen pt-5" v-else>
      <h1 class="m-4 text-white text-xl font-semibold">Dashboard</h1>
      <TabsWrapper class="py-6 px-5" @tab-changed="tabChanged">
        <Tabs title="User and Session Overview">
          <DashboardHeaderAdmin />
        </Tabs>
        <Tabs title="Application Usage Insights">
          <div
            class="flex flex-wrap justify-between self-center bg-white mt-5 p-4 m-4"
          >
            <div class="mt-2 mx-auto" style="width: 45%">
              <Chart
                v-if="oculusLabels.length > 0"
                :labels="oculusDates"
                label="Users have used the application"
                :data="oculusLabels"
                id="oculus"
                type="line"
              ></Chart>
            </div>
            <div class="mt-2" style="width: 45%">
              <DobleChart
                v-if="gemeLabels.length > 0"
                :labels="gameDates"
                :labels1="mobileDates"
                :data="gemeLabels"
                :data1="mobileLabels"
                id="sessions"
              ></DobleChart>
            </div>
            <div class="mt-4" style="width: 45%">
              <Chart
                v-if="trailMinutesLabels.length > 0"
                :labels="trialMinutesDates"
                label="Trials average time (minutes)"
                :data="trailMinutesLabels"
                id="games"
                type="bar"
              ></Chart>
            </div>
            <div class="mt-4" style="width: 45%">
              <Chart
                v-if="totalModes.length > 0"
                :labels="totalModes"
                label="Total times played by mode"
                :data="totalModesPlayed"
                id="modes"
                type="pie"
              ></Chart>
            </div>
          </div>
        </Tabs>
      </TabsWrapper>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import Chart from "@/components/general/Chart.vue";
import DobleChart from "@/components/general/DobleChar.vue";
import { totalSessions } from "@/services/session/session.js";
import { totalTrialAverage, getTotalModes } from "@/services/trial/trial.js";
import { oculusLogin } from "@/services/oculus/oculus.js";
import { useStore } from "vuex";
import DashboardHeader from "@/components/general/DashboardHeader.vue";
import DashboardHeaderAdmin from "@/components/general/DashboardHeaderAdmin.vue";
import Tabs from "@/components/general/Tabs.vue";
import TabsWrapper from "@/components/general/TabsWrapper.vue";

const store = useStore();

const user = computed(() => store.getters["user/getUserData"]);

const gameDates = ref([]);
const gemeLabels = ref([]);
const mobileDates = ref([]);
const mobileLabels = ref([]);
const oculusDates = ref([]);
const oculusLabels = ref([]);

const trialMinutesDates = ref([]);
const trailMinutesLabels = ref([]);
const totalModes = ref([]);
const totalModesPlayed = ref([]);
const activeTab = ref("User and Session Overview");

function getSessions() {
  totalSessions().then((response) => {
    response.data.mobile.filter((obj) => gameDates.value.push(obj.date));
    response.data.mobile.filter((obj) => gemeLabels.value.push(obj.count));
    response.data.oculus.filter((obj) => mobileDates.value.push(obj.date));
    response.data.oculus.filter((obj) => mobileLabels.value.push(obj.count));
  });
}

function getLoginList() {
  oculusLogin().then((response) => {
    response.data.filter((obj) => oculusDates.value.push(obj.date));
    response.data.filter((obj) => oculusLabels.value.push(obj.count));
  });
}

function getGameAverage() {
  totalTrialAverage().then((response) => {
    response.data.filter((obj) => trialMinutesDates.value.push(obj.date));
    response.data.filter((obj) =>
      trailMinutesLabels.value.push(obj.total_minutes)
    );
  });
}

function getAllModes() {
  getTotalModes().then((response) => {
    response.data.filter((obj) => totalModes.value.push(obj.mode));
    response.data.filter((obj) => totalModesPlayed.value.push(obj.count));
  });
}

function tabChanged(newTabTitle) {
  activeTab.value = newTabTitle;
}

watch(activeTab, async (newTab) => {
  if (newTab === "Application Usage Insights") {
    getSessions();
    getLoginList();
    getGameAverage();
    getAllModes();
  }
});
</script>

<style scoped>
img {
  border-radius: 50%;
  background-color: #edfe86;
  width: 52px;
  height: 52px;
}

#card {
  width: 30%;
}
</style>
